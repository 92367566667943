import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { AuthGuardFxn } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    canActivate: [AuthGuardFxn],
    loadChildren: () => import('./pages/auth/auth.route').then(m => m.routes)
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuardFxn],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/after-login-page/pages-route').then(m => m.routes)
      }
    ]
  },
  {
    path: 'resetPassword',
    loadComponent: () => import(
      './pages/no-login-pages/user-reset-password/user-reset-password.component'
    ).then(m => m.UserResetPasswordComponent)
  }
];

RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled'
});

import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideHttpClient,
  HttpClient,
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxEchartsModule } from 'ngx-echarts';
import { ToastrModule } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetInterceptor } from './core/interceptor/get-interceptor.service';
import { SetInterceptor } from './core/interceptor/set-interceptor.service';
import { InitialService } from './services/initial.service';

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

type FactoryFunction = () => void;

export function initialServiceFactory(
  InitialService: InitialService
): FactoryFunction {
  return () => InitialService.setUpGoogleScript();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      ToastrModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts')
      })
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initialServiceFactory,
      deps: [InitialService],
      multi: true
    },
    { provide: MAT_DIALOG_DATA,
      useValue: '' },
    { provide: MatDialogRef,
      useValue: '' }
  ]
};

import { Component, HostListener, OnDestroy } from '@angular/core';
import { ModuleConst, sideBar } from '../../core/constants/sidebar.const';
import { assets } from '../../core/constants/assets.const';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { LoginResponse } from '../../models/auth.model';
import { SideBar } from '../../models/sideBar.model';
import { userRole } from '../../core/constants/keys.constant';

@Component({
  selector: 'treasure-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnDestroy {

  protected moduleConst = ModuleConst;
  protected assetsConst = assets;
  public sideBar = sideBar;
  public innerWidth = 0;
  public sidenavWidth = 5;
  protected subscriptionSubject$: Subscription;
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public _authService: AuthService, private _translateService: TranslateService) {
    this.subscriptionSubject$ = this._authService.profileDetails
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: LoginResponse | null) => {
        if (res !== null) {
          this.handleSideBar(res);
        }
      });
  }
  increase(): void {
    this.sidenavWidth = 15;
  }
  decrease(): void {
    this.sidenavWidth = 4;
  }
  @HostListener('window', ['$event'])
  toggleSidenav(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1199.98) {
      this._authService.toggleSidebarView();
    }
  }

  handleSideBar(res: LoginResponse): void {
    if (res.role === userRole.SubAdmin) {
      this.subAdminRoleHandler(res);
    } else {
      this.sideBar.forEach((el: SideBar) => {
        el.isView = el.isAdd = el.isEdit = el.isDelete = true;
      });
    }
  }

  subAdminRoleHandler(res: LoginResponse): void {
    const removeableItems = new Set([this.moduleConst.SubAdmin]);
    this.sideBar = this.sideBar.filter(item => {
      if (removeableItems.has(item.id)) {
        return false;
      }
      const current = res.permission?.permission?.find(
        el => el.id === item.id
      );
      if (current) {
        Object.assign(item, {
          isView: current.isView,
          isAdd: current.isAdd,
          isEdit: current.isEdit,
          isDelete: current.isDelete
        });
      }
      return true;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
  
}

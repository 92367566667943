/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { EncryptionService } from '../../services/encryption.service';
  
dayjs.extend(utc);
dayjs.extend(tz);
  
  @Injectable({
    providedIn: 'root'
  })
export class SetInterceptor implements HttpInterceptor {
  constructor(
      private translate: TranslateService,
      private _authService: AuthService,
      private _encryptionSerivce: EncryptionService
  ) {
    // Todo
  }
  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    return next.handle(clonedReq);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  handleRequest(req: HttpRequest<any>) {
    const storageKey = localStorage.getItem(environment.storageKey);
    const token: string | null = storageKey ? storageKey : null;
  
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let authReq: any;
    const headers = {
      'Content-Type': 'application/json',
      authorization: token ? `Bearer ${token}` : '',
      date: dayjs.default().valueOf().toString()
    };
    if (req.url.includes('admin/setPassword')) {
      this._authService.resetTokenSubject.subscribe((res: string) => {
        headers.authorization = `Bearer ${res}`;
      });
    }
  
    // eslint-disable-next-line prefer-const
    authReq = req.clone({
      headers: new HttpHeaders({
        hash: this._encryptionSerivce.encryptHeaders(headers),
        deviceType: 'web',
        language: this.translate.currentLang || ''
      })
    });
  
    if (
      req.method.toLowerCase() === 'post' ||
        req.method.toLowerCase() === 'put'
    ) {
      if (req.url.includes('admin/upload')) {
        // Do nothing
      } else {
        authReq['body'] = {
          hash: this._encryptionSerivce.encryptionPayload(
            JSON.stringify(authReq.body)
          )
        };
      }
    }
    return authReq;
  }
}
  
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { encryptionKeys } from '../core/constants/keys.constant';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  // TODO

  iv = CryptoJS.enc.Base64.parse(encryptionKeys.IV); // giving empty initialization vector
  key = CryptoJS.SHA256(encryptionKeys.key); // hashing the key using SHA256
  encryptedString: any;
  encryptionPayload(data: any): any {
    data = data.slice();
    this.encryptedString = CryptoJS.AES.encrypt(data, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return this.encryptedString.toString();
  }

  encryptHeaders(data: any, type = 'header'): any {
    if (typeof data === 'string') {
      data = data.slice();
      this.encryptedString = CryptoJS.AES.encrypt(data, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    } else {
      this.encryptedString = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.key,
        {
          iv: this.iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );
    }

    if (type === 'query' && this.encryptedString.toString().includes('+')) {
      this.encryptedString = this.encryptedString
        .toString()
        .split('+')
        .join(environment.storageKey);
    }

    return this.encryptedString.toString();
  }
}

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { ToasterService } from '../../services/toaster.service';
export const AuthGuardFxn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  const _authService = inject(AuthService);
  const router = inject(Router);
  const _toasterService = inject(ToasterService);
  const _translateService = inject(TranslateService);
  if (state.url.includes('/auth/login')) {
    if (_authService.isAuthenticated()) {
      router.navigate(['/dashboard']);
      return false;
    }
    return true;
  } else {    
    if (!_authService.isAuthenticated()) {
      router.navigate(['/auth/login']);
      _toasterService.errToastr(_translateService.instant('LoginFirst'));
      return false;
    }
    return true;
  }
};

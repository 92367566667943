import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { assets } from '../../core/constants/assets.const';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { AuthService } from '../../services/auth.service';
import { SpinnerService } from '../../services/spinner.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { LoginResponse, logoutResponse } from '../../models/auth.model';
import { responseStatus } from '../../core/constants/responseStatus.const';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'treasure-main',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    CommonModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    MatBadgeModule
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnInit, OnDestroy {

  public assets = assets;
  public sidenavWidth = 200;
  protected subscriptionSubject$!: Subscription;
  private destroy: Subject<boolean> = new Subject<boolean>();
  protected profile: LoginResponse | null = null;
  constructor(public _authService: AuthService, private spinnerService: SpinnerService, private router: Router) {}
  toggleSidenav(): void {
    this._authService.toggleSidebarView();
  }

  ngOnInit(): void {
    this.subscriptionSubject$ = this._authService.profileDetails
      .pipe(takeUntil(this.destroy))
      .subscribe((res: LoginResponse | null) => {
        if (res !== null) {
          this.profile = res;
        } else {
          this.getProfile();
        }
      });
  }

  getProfile(): void {
    this.spinnerService.addToLoader('getProfile');
    this._authService
      .getProfile()
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (res: LoginResponse) => {
          this.spinnerService.removeFromLoader('getProfile');
          this.profile = res;
          this._authService.profileDetails.next(res);
        },
        error: () => {
          this.spinnerService.removeFromLoader('getProfile');
        }
      });
  }

  logout(): void {
    this._authService.logoutAlert().then((res: any) => {
      if (
        Object.prototype.hasOwnProperty.call(res, 'value') &&
        res.isConfirmed === true
      ) {
        this.spinnerService.addToLoader('logout');
        this._authService
          .logout()
          .pipe(takeUntil(this.destroy))
          .subscribe({
            next: (res: logoutResponse) => {
              if (res.statusCode === responseStatus.SUCCESS) {
                localStorage.removeItem(environment.storageKey);
                this.spinnerService.removeFromLoader('logout');
                this.router.navigate(['/auth/login']);
              }
            },
            error: () => {
              this.spinnerService.removeFromLoader('logout');
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.complete();
    this.destroy.unsubscribe();
  }

}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import 'zone.js';
import { CommonModule } from '@angular/common';
import { NgxUiLoaderModule } from 'ngx-ui-loader';


@Component({
  selector: 'treasure-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, TranslateModule, HttpClientModule, NgxUiLoaderModule],
  providers: [TranslateService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'treasure';
  
  constructor(public translate: TranslateService, private router: Router) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
  }
  
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        document.body.scrollTop = 0;
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      }
    });
  }
}

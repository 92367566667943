<mat-nav-list class="sd_bar">
  <button class="closeSidebar" (click)="toggleSidenav()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="logo-brand">
    <figure class="logo">
      <img src="{{ assetsConst.logo }}" alt="logo" />
    </figure>
    <figure class="logo1">
      <img src="{{ assetsConst.logo }}" alt="logo" />
    </figure>
    <!-- <h1>LOGO</h1> -->
  </div>
  <div *ngFor="let item of sideBar">
    @if (!item.isSubMenu) {
      @if (item.isView) {
        <mat-list-item
        [routerLink]="item.path"
        routerLinkActive="active"
        matTooltip="{{ item.label }}"
        matTooltip="Tooltip!"
        matTooltipPosition="right"
        (click)="toggleSidenav()">
        <figure class="sd_cn">
          <img src="{{ item.img }}" alt="" />
        </figure>
        <div class="sidenav-item">
          <h5 class="lead">{{ item.label | translate }}</h5>
        </div>
      </mat-list-item>
      }
    }
    @if (item.isSubMenu) {
      <mat-accordion>
        <mat-expansion-panel class="drop_lst_nv">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-list-item
                matTooltip="{{ item.label }}"
                matTooltip="Tooltip!"
                matTooltipPosition="right"
                (click)="toggleSidenav()">
                <figure class="sd_cn" mat-raised-button>
                  <img src="{{ item.img }}" alt="" />
                </figure>
                <div class="sidenav-item">
                  <h5 class="lead">{{ item.label | translate }}</h5>
                </div>
              </mat-list-item>
            </mat-panel-title>
          </mat-expansion-panel-header>
          @for (sub of item.subMenuItems; track $index) {
            <mat-list-item
            [routerLink]="sub.path"
            matTooltip="{{ item.label }}"
            matTooltip="Tooltip!"
            matTooltipPosition="right"
            (click)="toggleSidenav()">
            <figure class="sd_cn">
              <img src="{{ sub.img }}" alt="images" />
            </figure>
            <div class="sidenav-item">
              <h5 class="lead">{{ sub.label | translate }}</h5>
            </div>
          </mat-list-item>
          }
        </mat-expansion-panel>
      </mat-accordion>
    }
  </div>
</mat-nav-list>

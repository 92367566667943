<mat-sidenav-container fullscreen>
  <mat-sidenav #sidenav mode="side" class="example-sidenav" [ngStyle]="{ 'width.em': sidenavWidth }"
    [opened]="_authService.isOpen$ | async">
    <treasure-sidebar></treasure-sidebar>
  </mat-sidenav>
  <div class="example-sidenav-content">
    <div class="top_header">
      <div class="toggle" (click)="toggleSidenav()">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="top_header_ryt">
        <div class="user-pf">
          <figure mat-button [matMenuTriggerFor]="menu">
            <img [src]="assets.adminIcon" alt="profile" />
            <figcaption>
              <mat-icon>arrow_drop_down</mat-icon>
            </figcaption>
          </figure>
          <mat-menu #menu="matMenu" class="dropbox" xPosition="before" yPosition="below">
            <ul class="hd_menu">
              <li routerLink="/manage-profile">
                <mat-icon>person</mat-icon>
                {{ 'myProfile' | translate }}
              </li>
              <li (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                {{ 'Logout' | translate }}
              </li>
            </ul>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="main_wrapper">
      <router-outlet />
    </div>
    <mat-menu #menu="matMenu"> </mat-menu>
  </div>
</mat-sidenav-container>